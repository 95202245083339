import React from "react"
import { graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { boardMemberPath } from "utils/company"
import BoardMemberPage from "components/board-member-page"
import { getSharePreviewUrls } from "utils/share-preview"

const BoardMember = ({ data }) => {
  const { boardMember, page } = data.gcms

  return (
    <Layout>
      <SEO
        title={boardMember.fullName}
        description={page.description}
        path={boardMemberPath(boardMember)}
        sharePreviewUrls={getSharePreviewUrls(page)}
      />
      <BoardMemberPage boardMember={boardMember} />
    </Layout>
  )
}

export default BoardMember

export const query = graphql`
  query BoardMemberById($id: ID!) {
    gcms {
      boardMember(where: { id: $id }) {
        id
        fullName
        position
        path
        bio {
          html
        }
        picture {
          altText
          url
          base64
          mimeType
          height
          width
          handle
          gatsbyImageData(width: 512, placeholder: "blurred")
        }
      }
      page(where: { path: "/company" }) {
        ...PageData
      }
    }
  }
`
