import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const Styles = styled.div`
  padding-top: 80px;

  .main-wrapper {
    ${tw`flex flex-col font-display text-priority-lightP1 text-left bg-white pt-12 m:pt-20 pb-44`}

    .main {
      ${tw`mx-auto max-w-s m:max-w-m`}

      @media (min-width: 1200px) {
        max-width: 800px;
      }

      a {
        ${tw`underline`}
      }

      a.back {
        ${tw`no-underline uppercase text-sm text-neonred`}

        &:hover {
          .text {
            ${tw`underline`}
          }
        }

        .arrow {
          ${tw`text-base`}
        }
      }

      strong {
        ${tw`font-display`}
      }

      .name {
        ${tw`mt-8 m:mt-0`}
      }

      .position {
        ${tw`hidden font-body`}

        font-size: 22px;
      }

      .picture {
        ${tw`flex-shrink-0`}

        border-radius: 5px;

        @media (min-width: 640px) {
          width: 240px;
        }
      }

      .bio {
        ${tw`font-body mt-16`}

        p {
          ${tw`mb-5`}
        }
      }
    }
  }
`

const LargeTitle = styled.h1`
  ${tw`font-bold`}

  font-size: 30px;
  line-height: 35px;

  @media (min-width: 640px) {
    margin-bottom: -8px;
    font-size: 45px;
    line-height: 53px;
  }

  @media (min-width: 1024px) {
    margin-bottom: -19px;
    font-size: 60px;
    line-height: 79px;
  }
`

const BackLink = ({ className }) => {
  return (
    <Link className={`back ${className || ""}`} to="/company/#leadership">
      <span className="arrow">❮&nbsp;&nbsp;</span>
      <span className="text">Parrot Analytics Leadership</span>
    </Link>
  )
}

export default function BoardMemberPage({ boardMember }) {
  return (
    <Styles>
      <div className="main-wrapper">
        <div className="main">
          <BackLink className="m:hidden mb-8 inline-block" />
          <div className="flex flex-col-reverse m:flex-row justify-between">
            <div className="flex flex-col justify-between">
              <BackLink className="hidden m:inline-block" />
              <div>
                <LargeTitle className="name">{boardMember.fullName}</LargeTitle>
              </div>
            </div>
            <GatsbyImage
              image={getImage(boardMember.picture)}
              alt={boardMember.fullName}
              className="picture"
            />
          </div>
          <div
            className="bio typetura-article"
            dangerouslySetInnerHTML={{
              __html: boardMember.bio?.html,
            }}
          />
        </div>
      </div>
    </Styles>
  )
}
